<!--
  PACKAGE_NAME : src/pages/euc/device/avaya
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 2024-04-19
  DESCRIPTION :
-->
<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_type_device"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="$_searchData($event, 'search_type_device')"
      >
        <template v-slot:before>
          <DxSelectBox
            v-model="searchType.customTypes['svrAes.svr.svrInx']"
            placeholder="서버 선택"
            :items="getSVRList"
            display-expr="codeNm"
            value-expr="codeValue"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('svrAes.svr.svrInx', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="deviceGrid" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
          gradeList: null,
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            'svrAes.svr.svrInx': null,
          },
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          excel: {
            title: '전화 정보 목록',
          },
          refName: 'deviceGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 340px)',
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true,
          },
          showActionButtons: {
            excel: true, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '모델',
              dataField: 'model',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: async (container, options) => {
                const imgTag = document.createElement('img');
                const imgData = this.$_getAttachFileURL(null, options.value);
                imgTag.setAttribute('src', imgData);
                container.append(imgTag);
              },
            },
            {
              caption: '타입',
              dataField: 'modelType',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '내선번호',
              dataField: 'uniqueId',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              fixedPosition: 'left',
              cellTemplate: (container, options) => {
                const { modelType } = options.data;
                if (modelType === 'IP') {
                  const aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    this.onUpdateData(options.data);
                  });
                  container.append(aTag);
                } else {
                  container.append(options.value);
                }
              },
            },
            {
              caption: '릴리즈 버전',
              dataField: 'release',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '서버 번호',
              dataField: 'svrAes.svr.svrInx',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '교환기',
              dataField: 'svrAes.aes.cm.cmNm',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: 'AES명',
              dataField: 'svrAes.aes.aesNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: 'IP',
              dataField: 'ip',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '로그인',
              dataField: 'state',
              width: 200,
              height: 40,
              alignment: 'center',
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              cellTemplate: (container, options) => {
                const stateEl = document.createElement('div');
                const color = options.value === 'true' ? '#21b6bb' : '#808080';

                stateEl.style.display = 'inline-block';
                stateEl.style.width = '18px';
                stateEl.style.height = '18px';
                stateEl.style.borderRadius = '50%';
                stateEl.style.backgroundColor = color;

                container.append(stateEl);
              },
            },
            {
              caption: 'MCID',
              dataField: 'inauth',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    onValueChanged: e => {
                      this.onChangedMCID(options.data, e.value);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {
      /** @description: U_CODE svr_type 가져오기 */
      getSVRList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        const result = this.$_getCode('svr_type').filter(d => d.delFl === 'N');
        const svrInx = params?.['svrAes.svr.svrInx'] ? params['svrAes.svr.svrInx'] : result[0].codeValue;
        this.setCustomTypes('svrAes.svr.svrInx', svrInx);
        return result;
      },
      /** @description: pageData -> pageSetting apiActionNm 가져오기 */
      getApiActionNm() {
        return this.config.pageSetting.config?.apiActionNm || null;
      },
    },
    methods: {
      async onDeleteData() {
        const selectedRowsData = this.$refs.deviceGrid.selectedRowsData;
        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        } else {
          const items = this.$refs.deviceGrid.getItems;
          let allItems = [];
          selectedRowsData.forEach(data => {
            allItems = [...new Set(allItems.concat(items.filter(item => item.uniqueId === data.uniqueId)))];
          });

          const payload = {
            actionname: 'EUC_DEVICE_DELETE',
            data: {
              data: allItems,
            },
          };
          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_msgContents('CMN_SUC_DELETE'), { icon: 'success' });
            this.$refs.deviceGrid.refreshData();
          } else {
            this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
          }
        }
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '삭제',
              elementAttr: { class: 'btn_XS white light_filled trash' },
              width: 60,
              height: 30,
              onClick: () => {
                this.onDeleteData();
              },
            },
            location: 'before',
          },
        ];

        const useRestart = this.$_getSystemData('use_device_restart')?.configValue === 'true';
        if (useRestart) {
          buttons.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: '재시작',
              elementAttr: { class: 'btn_XS default filled ' },
              width: 80,
              height: 30,
              onClick: () => {
                this.onDeviceRestart();
              },
            },
            location: 'before',
          });
        }

        const useLogin = this.$_getSystemData('use_device_login')?.configValue === 'true';
        if (useLogin) {
          buttons.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: '로그인',
              elementAttr: { class: 'btn_XS default filled ' },
              width: 80,
              height: 30,
              onClick: () => {
                this.onDeviceLogin();
              },
            },
            location: 'before',
          });
        }

        return buttons;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-regDt,-id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData, ...this.searchType.customTypes };

            if (params?.['svrAes.svr.svrInx'] === '0') {
              delete params['svrAes.svr.svrInx'];
            }

            const payload = {
              actionname: 'EUC_AVAYA_DEVICE_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: MCID 상태 변경 처리 메서드 */
      async onChangedMCID(rowData, value) {
        const data = [rowData];
        const payload = {
          actionname: 'DEVICE_MCID_UPDATE',
          data: {
            data,
            inauth: value ? 'Y' : 'N',
          },
          loading: false,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast('변경되었습니다.');
          this.$refs.deviceGrid.refreshData();
        } else {
          this.$_Toast('업데이트 실패 :(');
        }
      },
      /** @description : 단말기 재시작 실행 처리 메서드 */
      onDeviceRestart() {
        const selectedRowsData = this.$refs.deviceGrid.selectedRowsData;

        if (!selectedRowsData?.length) {
          this.$_Msg('단말기를 선택해주세요.');
          return false;
        }

        const adimPort = this.$_getSystemData('adim_port')?.configValue;
        const groups = selectedRowsData.reduce((acc, obj) => {
          const { svrAes } = obj;
          const { svr, aes } = svrAes;
          const url = `${svr?.svrIp}:${adimPort}_${aes?.cm?.cmNm}`;
          acc[url] = acc[url] ?? [];
          acc[url].push(obj);
          return acc;
        }, {});

        let apiArry = [];
        for (const url in groups) {
          const params = url.split('_');
          const payload = {
            actionname: 'DEVICE_RESTART',
            data: {
              url: params[0],
              params: {
                INTERVAL: '5',
                STATIONS: groups[url].map(item => item.uniqueId),
                CM: params[1],
              },
            },
            loading: false,
          };
          apiArry.push(this.CALL_API(payload));
        }
        Promise.all(apiArry).then(() => this.$_Toast('등록 되었습니다.'));
      },
      /** @description : 단말기 로그인 실행 처리 메서드 */
      async onDeviceLogin() {
        const length = this.$refs.deviceGrid.selectedRowsData.length;
        if (length === 0) {
          this.$_Msg('단말기를 선택해주세요.');
          return false;
        } else if (length > 1) {
          this.$_Msg('하나의 단말기만 선택해주세요.');
          return false;
        }

        const device = this.$refs.deviceGrid.selectedRowsData[0];
        const { uniqueId, svrAes } = device;
        const { svr } = svrAes;
        const url = `${svr?.svrIp}:${svr?.ucgwPort}`;
        const payload = {
          actionname: 'DEVICE_LOGIN',
          data: {
            url,
            params: {
              device: uniqueId,
            },
          },
        };

        const res = await this.CALL_API(payload);
        if (res.status === 200) {
          this.$_Toast('등록 되었습니다.');
        } else {
          this.$_Toast('요청을 실패하였습니다.');
        }
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD');
        }
      },
      /** @description : 단말 정보 상세 페이지 이동 메서드 */
      async onUpdateData(data) {
        const { svr } = data?.svrAes;
        const efgwPort = this.$_getSystemData('efgw_port')?.configValue;

        const payload = {
          actionname: 'EUC_AVAYA_DEVICE_DETAIL_INFO',
          data: {
            params: {
              url: `${svr?.svrIp}:${efgwPort}`,
              uniqueId: data.uniqueId,
            },
          },
          loading: false,
        };

        const res = await this.CALL_API(payload);
        const { result, fileData } = res.data;

        if (res.status === 200) {
          if (result === 'success') {
            this.$store.commit('setDetailParams', { deviceData: data, fileData });
            this.$router.push({ path: '/euc/device/avaya/detail' });
          } else if (result === 'not found') {
            this.$_Msg('해당 장치의 파일 데이터가 존재하지 않습니다.');
          } else {
            this.$_Msg('오류가 발생했습니다.');
          }
        } else {
          this.$_Msg('오류가 발생했습니다.');
        }
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>